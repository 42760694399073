import { AlkimiaOptions, AwsResourceConfiguration, Feature, FeatureFlagManager, SelectedResource } from '@amzn/alkimia-model';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Box,
  Button,
  Flashbar,
  FlashbarProps,
  Pagination,
  Popover,
  Select,
  SpaceBetween,
  Spinner,
  Table,
  TextFilter,
  Toggle,
} from '@amzn/awsui-components-react-v3';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import React, { ReactNode, useEffect, useState } from 'react';
import { deleteResourceSelections } from '../../../api/calls/deleteResourceSelections';
import { getResourceSelections } from '../../../api/calls/getResourceSelections';
import { saveResourceSelections } from '../../../api/calls/saveResourceSelections';
import { mutateToHaveCrIds, filterSelectedItemsWithoutCRs, } from '../../../utils';
import { CONSTRUCT_OPTIONS, IGNORED_RESOURCE_TYPES, MANAGED_BY_CLOUDFORMATION_OPTIONS, RELEVANT_OPTIONS } from '../../../utils/Constants';
import { AwsRumClient } from '../../../utils/awsRum';
import { COLUMN_DEFINITIONS, DEFAULT_PREFERENCES, Preferences, SEARCHABLE_COLUMNS, TableHeader } from '../tableConfig';
import { CoverageAnalysisOverview } from './CoverageAnalysisOverview';
import { EmptyStateProps, ItemType, SelectedOption, defaultConstruct, defaultManagedByCloudFormation, defaultRelevantOption } from './Types';
import { CollectionActions } from '@amzn/awsui-collection-hooks/cjs/interfaces';

export type AlkimiaResourceSelectionProps = {
  token: CognitoToken,
  tableItems: ItemType[],
  allTableItems: ItemType[],
  listResources: AwsResourceConfiguration[],
  selectedItems: ItemType[],
  isVerifiedResourcesToggle: boolean,
  alkimiaOptions: AlkimiaOptions,
  targetAccountID?: string,
  regionID?: string,
  selectedResources?: SelectedResource[],
  setClusters: React.Dispatch<React.SetStateAction<Map<string, ItemType[]>>>,
  setSortedListResources: React.Dispatch<React.SetStateAction<AwsResourceConfiguration[]>>,
  setTableItems: React.Dispatch<React.SetStateAction<any>>,
  setAllTableItems: React.Dispatch<React.SetStateAction<any>>,
  setSelectedItems: React.Dispatch<React.SetStateAction<any>>,
  setVerifiedResourcesToggle: React.Dispatch<React.SetStateAction<boolean>>,
  setClusteredListCheckState: React.Dispatch<React.SetStateAction<boolean[]>>,
  saveSelectedItemsInSessionStorage?: (accountID: string, regionID: string, selectedItems: ItemType[]) => void;
  decompressFunction?: (base64String: string) => string;
}

// Custom RUM event is added here to count number of times we count the matches.
// This serves as a proxy to count if user is using the search function as everytime
// filtering is used this function will be called to count number of matches.
const getFilterCounterText = (count) => {
  const rumClient = AwsRumClient.getRumClient();
  if (rumClient) {
    rumClient.recordRumEvent('SearchFilter', { count: 1 });
  }
  return `${count} ${count === 1 ? 'match' : 'matches'} based on filters`;
};

/**
 * Child component of ResourceSelectionComponent that display data in a table
 * @param tableItems - items that will be displayed on the table
 * @param listResources - AwsResourceConfiguration[], basically the data we recieved to display
 * @returns TableComponent for ResourceSelection Page
 */
function ResourceSelectionComponent({
  token,
  allTableItems,
  tableItems,
  listResources,
  selectedItems,
  isVerifiedResourcesToggle,
  alkimiaOptions,
  targetAccountID,
  regionID,
  selectedResources,
  setClusters,
  setSortedListResources,
  setTableItems,
  setAllTableItems,
  setSelectedItems,
  setClusteredListCheckState,
  setVerifiedResourcesToggle,
  saveSelectedItemsInSessionStorage,
  decompressFunction,
}: AlkimiaResourceSelectionProps) {
  const [construct, setConstruct] = useState('0');
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const [awsService, setAwsService] = useState('0');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listOfAwsServicesOptions, setListOfAwsServicesOptions] = useState<SelectedOption[]>([{ label: 'All AWS Services', value: 'DEFAULT' }]);
  const [selectListOfAwsServiceOptions, setSelectListOfAwsServiceOptions] = useState<SelectedOption>(listOfAwsServicesOptions[0]);
  const [selectedFilterConstruct, setSelectedFilterConstruct] = useState<SelectedOption>(CONSTRUCT_OPTIONS[0]);
  const [selectedRelevantOption, setSelectedRelevantOption] = useState<SelectedOption>(RELEVANT_OPTIONS[0]);
  const [selectedManagedByCloudFormationOption, setSelectedManagedByCloudFormationOption] = useState<SelectedOption>(MANAGED_BY_CLOUDFORMATION_OPTIONS[0]);
  const [constructOptions, setConstructOptions] = useState<SelectedOption[]>(CONSTRUCT_OPTIONS);
  const [relevantOptions, setRelevantOptions] = useState<SelectedOption[]>(RELEVANT_OPTIONS);
  const [managedByCloudFormationOptions] = useState<SelectedOption[]>(MANAGED_BY_CLOUDFORMATION_OPTIONS);
  const [relevant, setRelevant] = useState('0');
  const [managedByCloudFormation, setManagedByCloudFormation] = useState<string>('0');
  const [notificationItems, setNotificationItems] = useState<FlashbarProps.MessageDefinition[]>([]);

  const processCurrentResource = (funcUsedToProcess) => listResources.forEach((currentService) => {
    funcUsedToProcess(currentService);
  });

  /**
   * Creates an error content component for resource selection errors
   * Sole puprose is to be ingested in processResourceSelectionErrors
   */
  const createSelectionErrorContent = (selectionErrorMessage: string): ReactNode => {
    const alkIssueSimLink = 'https://sim.amazon.com/issues/create?template=da0d26a2-abe4-439e-9f67-324ea1b57b01';
    const contentMsg = `${selectionErrorMessage} Please refresh the page. If the issue persists cut a `;
    return <div><b>{contentMsg}</b><a href={alkIssueSimLink}>ticket</a>.</div>
  }

  /**
   * Creates a FlashNotification React element and then updates notification item state.
   */
  const processResourceSelectionErrors = (statusCode: string, header: string, selectionErrorMessage: string): void => {
    const flashmsgId = `${statusCode}#${selectionErrorMessage}`;
    const flashMsg: FlashbarProps.MessageDefinition = {
      header: header,
      type: "warning",
      content: createSelectionErrorContent(selectionErrorMessage),
      dismissible: true,
      id: flashmsgId,
      onDismiss: () =>
        setNotificationItems(notificationItem =>
          notificationItem.filter(item => item.id !== flashmsgId)
        )
    };

    const currNotifications = notificationItems;
    currNotifications.push(flashMsg);
    setNotificationItems(currNotifications);
  }

  /**
   * Sets all the filter states back to default values
   */
  const resetAllTableFilters = (actions: CollectionActions<ItemType>) => {
    // sets all the aws service filter back to default value
    setSelectListOfAwsServiceOptions(listOfAwsServicesOptions[0]);
    setAwsService('0');

    // sets all the relevant options value back to default value
    setSelectedRelevantOption(RELEVANT_OPTIONS[0]);
    setRelevant('0');

    // sets user created or default resource back to default value
    setSelectedManagedByCloudFormationOption(MANAGED_BY_CLOUDFORMATION_OPTIONS[0]);
    setManagedByCloudFormation('0');

    // set text filter back to empty value state
    actions.setFiltering('');
  }

  const matchesConstruct = (item, selectedConstruct) => selectedConstruct === defaultConstruct.value || item.supportedConstruct === selectedConstruct;

  const matchesRelevant = (item, selectedRelevant) => selectedRelevant === defaultRelevantOption.value || item.relevant === (selectedRelevant === 'Yes');

  const matchesAwsService = (item, selectedAwsService) => (selectedAwsService === '0' || selectedAwsService === 'DEFAULT' || item.service === selectedAwsService);

  const matchesManagedByCloudFormation = (item, selectedManagedByCloudFormation) => selectedManagedByCloudFormation === defaultManagedByCloudFormation.value || item.managedByCfn === (selectedManagedByCloudFormation === 'Yes');

  const FullPageHeader = ({ ...props }) => (
    <TableHeader
      variant="awsui-h1-sticky"
      title="Total Discovered Resources"
      actionButtons={(
        <SpaceBetween size="s" direction="horizontal">
          <div data-testid="usc-spinner">
            {isProcessing === true ? <Spinner size="normal" /> : ''}
          </div>
        </SpaceBetween>
      )}
      {...props}
    />
  );

  interface ApiError {
    response?: {
      status?: number;
      data?: {
        message: string;
      };
    };
    message?: string;
  }

  const getSelectedItemsDiff = (arr1: ItemType[], arr2: ItemType[]): ItemType[] =>
    arr1.filter(item => !arr2.some(i => i.name === item.name && i.type === item.type));

  const processChanges = async (
    items: ItemType[],
    isDelete: boolean,
    prevItems: ItemType[],
    token: CognitoToken,
    convertToResource: (item: ItemType) => SelectedResource,
    processResourceSelectionErrors: (code: string, header: string, message: string) => void
  ): Promise<ItemType[]> => {
    if (!items.length) return prevItems;

    try {
      const apiCall = isDelete ? deleteResourceSelections : saveResourceSelections;
      const result = await apiCall(token, items.map(convertToResource));

      if (!result) {
        throw new Error('Invalid API response');
      }

      const successItems = items.filter(item =>
        !result.failed.some(f => f.id === item.name && f.resourceType === item.type)
      );

      if (result.failed.length > 0) {
        processResourceSelectionErrors(
          '207',
          `Partial ${isDelete ? 'deselection' : 'selection'} failure`,
          `Some resources could not be ${isDelete ? 'deselected' : 'selected'}.`
        );
      }

      return isDelete
        ? prevItems.filter(item => !successItems.some(s => s.name === item.name && s.type === item.type))
        : [...prevItems, ...successItems];

    } catch (error) {
      const apiError = error as ApiError;
      const errorCode = apiError.response?.status?.toString() ?? '500';
      const erroMsg = `Failed to ${isDelete ? 'deselect' : 'select'} resources.`;
      processResourceSelectionErrors(
        errorCode,
        'Selection Error',
        erroMsg
      );
      return prevItems;
    }
  };

  const handleSelectionChanges = async (
    newItems: ItemType[],
    prevItems: ItemType[],
    tableItems: ItemType[],
    token: CognitoToken,
    convertToResource: (item: ItemType) => SelectedResource,
    processResourceSelectionErrors: (code: string, header: string, message: string) => void
  ): Promise<ItemType[]> => {
    try {
      const toAdd = getSelectedItemsDiff(newItems, prevItems);
      const toRemove = getSelectedItemsDiff(prevItems, newItems);
      const isSelectAll = newItems.length === tableItems.length && prevItems.length < tableItems.length;
      const isDeselectAll = newItems.length === 0 && prevItems.length > 0;

      if (isSelectAll) {
        return await processChanges(
          tableItems,
          false,
          prevItems,
          token,
          convertToResource,
          processResourceSelectionErrors
        );
      }

      if (isDeselectAll) {
        return await processChanges(
          prevItems,
          true,
          prevItems,
          token,
          convertToResource,
          processResourceSelectionErrors
        );
      }

      let updatedItems = prevItems;
      if (toAdd.length) {
        updatedItems = await processChanges(
          toAdd,
          false,
          prevItems,
          token,
          convertToResource,
          processResourceSelectionErrors
        );
      }
      if (toRemove.length) {
        updatedItems = await processChanges(
          toRemove,
          true,
          updatedItems,
          token,
          convertToResource,
          processResourceSelectionErrors
        );
      }
      return updatedItems;

    } catch (error) {
      console.error('Selection operation failed:', error);
      return prevItems;
    }
  };

  const updateTableState = (
    selectedItems: ItemType[] | SelectedResource[],
    itemListWithCR: ItemType[]
  ) => {
    setSelectedItems(selectedItems);
    setTableItems(itemListWithCR.filter((item) => item.verifiedByAlkimia && item.supportedConstruct === 'L2'));
    setAllTableItems(itemListWithCR);
  };

  const getItems = async () => {
    let itemList: ItemType[] = [];
    const itemsWithNoClusters: ItemType[] = [];
    const clusterMap: Map<string, ItemType[]> = new Map([]); // key is the clusterId, val is the list of resources associated to clusterId
    const sortedList = listResources.sort((a, b) => a.resourceType.localeCompare(b.resourceType));

    // Create options object list based on aws services in account
    const awsServiceSet: Set<{ lable: string, value: string }> = new Set([]);
    const visitedAwsServices: Set<string> = new Set([]);
    listResources.forEach((service) => {
      const currAwsService = service.resourceType.split('::')[1];
      if (!visitedAwsServices.has(currAwsService) && service.verifiedByAlkimia) {
        visitedAwsServices.add(currAwsService);
        awsServiceSet.add({
          lable: currAwsService,
          value: currAwsService,
        });
      }
    });
    setListOfAwsServicesOptions(listOfAwsServicesOptions.concat(Array.from(awsServiceSet.values()) as unknown as SelectedOption[]));
    setSortedListResources(sortedList);
    const myFunc = (currentConfiguration: AwsResourceConfiguration) => {
      const selectedPropertyExist = Object.prototype.hasOwnProperty.call(currentConfiguration, 'selected');
      let resourceName = '';
      // the currentConfiguration.ids.resourceName field can be overriden from the Alkimia Discovery logic to force a specific name from the discovered configuration.
      // Sample cr https://code.amazon.com/reviews/CR-150672536/revisions/1#/diff
      if (currentConfiguration.ids.resourceName && currentConfiguration.ids.resourceName !== '') {
        resourceName = currentConfiguration.ids.resourceName;
      } else if (currentConfiguration.ids.resourceId && currentConfiguration.ids.resourceId !== '') {
        resourceName = currentConfiguration.ids.resourceId;
      } else if (currentConfiguration.ids.arn && currentConfiguration.ids.arn !== '') {
        resourceName = currentConfiguration.ids.arn;
      }

      let resourceSelection = false;

      if (selectedPropertyExist) {
        resourceSelection = currentConfiguration.selected!;
      }

      const item: ItemType = {
        name: resourceName,
        selected: resourceSelection,
        type: currentConfiguration.resourceType,
        service: currentConfiguration.resourceType.split('::')[1],
        supportedConstruct: currentConfiguration.supportedConstructType!,
        relevant: currentConfiguration.relevant!,
        managedByCfn: currentConfiguration.managedByCfn!,
        clusterId: (currentConfiguration.clusterId) ? currentConfiguration.clusterId : '',
        verifiedByAlkimia: currentConfiguration.verifiedByAlkimia,
      };

      // Do not display any networking resources
      // Do not display resources that do not have
      // TODO update SDK query logic to disable scans for EC2 Networking resources
      if (!IGNORED_RESOURCE_TYPES.has(currentConfiguration.resourceType) && resourceName !== undefined && resourceName !== '') {
        itemList.push(item);

        if (currentConfiguration.clusterId) {
          if (clusterMap.has(currentConfiguration.clusterId)) {
            const clusterListOfItems = clusterMap.get(currentConfiguration.clusterId)!;
            clusterListOfItems.push(item);
            clusterMap.set(currentConfiguration.clusterId, clusterListOfItems);
          } else {
            clusterMap.set(currentConfiguration.clusterId, [item]);
          }
        } else {
          itemsWithNoClusters.push(item);
        }
      }
    };
    processCurrentResource(myFunc);

    if (FeatureFlagManager.getInstance().isFeatureFlagEnabled(alkimiaOptions, Feature.PERSIST_SELECTIONS)) {
      try {
        const persistedSelections: SelectedResource[] = await getResourceSelections(token, targetAccountID!, regionID!);
        mutateToHaveCrIds(itemList, persistedSelections);
        const selectedItems = filterSelectedItemsWithoutCRs(itemList, persistedSelections);
        updateTableState(selectedItems, itemList);
      } catch (error: any) {
        processResourceSelectionErrors(
          error.response?.status?.toString() ?? '500',
          'Warning',
          'We\'re unable to fetch existing resource selections.'
        );
        mutateToHaveCrIds(itemList, []);
      }
    } else {
      let selectedItems: ItemType[];
      if (selectedResources && sessionStorage.getItem(targetAccountID + '/' + regionID) && decompressFunction) {
        const sessionData: ItemType[] = JSON.parse(
          decompressFunction(sessionStorage.getItem(targetAccountID + '/' + regionID)!)
        );
        selectedItems = itemList.filter(item =>
          sessionData.some(s => s.name === item.name && s.type === item.type));
      } else {
        selectedItems = filterSelectedItemsWithoutCRs(itemList, selectedResources || []);
      }
      mutateToHaveCrIds(itemList, selectedResources || []);
      updateTableState(selectedItems, itemList);
    }
    
    const sortedClusterList = [...clusterMap.entries()].sort((a, b) => Number(a[0]) - Number(b[0]));
    sortedClusterList.push(['resourcesWithNoCluster', itemsWithNoClusters]); // create another cluster that contains items with no clusters
    setClusters(new Map(sortedClusterList)); // Sort based on ClusterId
    const checkedFalseItems: boolean[] = new Array<boolean>(sortedClusterList.length);
    checkedFalseItems.fill(false);
    setClusteredListCheckState(checkedFalseItems);
  };

  function EmptyState({
    title,
    subtitle,
    action,
  }: EmptyStateProps) {
    return (
      <Box textAlign="center" color="inherit">
        <Box variant="strong" textAlign="center" color="inherit">
          {title}
        </Box>
        <Box variant="p" padding={{ bottom: 's' }} color="inherit">
          {subtitle}
        </Box>
        {action}
      </Box>
    );
  }

  function convertToSelectedResource(item: ItemType): SelectedResource {
    return {
      account: targetAccountID!,
      region: regionID ?? '',
      resourceType: item.type,
      id: item.name,
      lastUpdated: new Date().toISOString(),
      lastUpdatedByUser: '',
      codeReviewIds: [],
      associations: []
    };
  }

  useEffect(() => {
    getItems();
  }, []);

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(
    tableItems,
    {
      filtering: {
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can't find a match."
            action={<Button onClick={() => resetAllTableFilters(actions)}>Clear filters</Button>}
          />
        ),
        filteringFunction: (item, filteringText) => {
          if (!matchesConstruct(item, construct) || !matchesRelevant(item, relevant)
            || !matchesAwsService(item, awsService) || !matchesManagedByCloudFormation(item, managedByCloudFormation)) {
            return false;
          }
          const filteringTextLowerCase = filteringText.toLowerCase();

          return SEARCHABLE_COLUMNS.map((key) => item[key])
            .some(
              (value) => typeof value === 'string' && value.toLowerCase()
                .indexOf(filteringTextLowerCase) > -1,
            );
        },
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: { keepSelection: true },
    },
  );

  return (
    <>
      <div id="select-table">
        <Table
          {...collectionProps}
          contentDensity="compact"
          selectionType="multi"
          header={(
            <>
              <FullPageHeader
                selectedItems={collectionProps.selectedItems}
                totalItems={tableItems}
                isProcessing={isProcessing}
              />
              {notificationItems.length > 0 && <div><Flashbar items={notificationItems} stackItems /></div>}
            </>
          )}
          selectedItems={selectedItems}
          variant="full-page"
          columnDefinitions={COLUMN_DEFINITIONS}
          visibleColumns={preferences.visibleContent}
          items={items}
          pagination={<Pagination {...paginationProps} />}
          filter={(
            <div className="input-container">
              <SpaceBetween direction="vertical" size="m">
                <SpaceBetween direction="horizontal" size="m">
                  <div className="select-filter">
                    <Select
                      options={listOfAwsServicesOptions}
                      selectedOption={selectListOfAwsServiceOptions}
                      onChange={({ detail: { selectedOption } }) => {
                        setSelectListOfAwsServiceOptions(selectedOption as SelectedOption);
                        setAwsService(selectedOption.value as string);
                      }}
                    />
                  </div>
                  <div className="select-filter">
                    <Select
                      options={relevantOptions}
                      selectedOption={selectedRelevantOption}
                      onChange={({ detail: { selectedOption } }) => {
                        setSelectedRelevantOption(selectedOption as SelectedOption);
                        setRelevant(selectedOption.value as string);
                      }}
                    />
                  </div>
                </SpaceBetween>
                <div className="select-filter" style={{ 
                  width: 'fit-content', 
                  minWidth: '300px',
                  maxWidth: '300px'
                }}>
                  <Select
                    options={managedByCloudFormationOptions}
                    selectedOption={selectedManagedByCloudFormationOption}
                    onChange={({ detail: { selectedOption } }) => {
                      setSelectedManagedByCloudFormationOption(selectedOption as SelectedOption);
                      setManagedByCloudFormation(selectedOption.value as string);
                    }}
                  />
                </div>
                <SpaceBetween direction="horizontal" size="s">
                  <div className="text-filter">
                      <TextFilter
                        {...filterProps}
                        filteringAriaLabel="Filter resources"
                        filteringPlaceholder="Find resources"
                      />
                  </div>
                  <div
                    style={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      paddingTop: '5px'
                    }}
                  >
                      {
                        (filterProps.filteringText
                          || construct !== defaultConstruct.value
                          || relevant !== defaultRelevantOption.value
                          || awsService !== 'DEFAULT'
                          || managedByCloudFormation !== defaultManagedByCloudFormation.value)
                        && (
                          <span
                            className="filtering-results"
                          >
                            {getFilterCounterText(filteredItemsCount)}
                          </span>
                        )
                      }
                  </div>
                </SpaceBetween>
              </SpaceBetween>
            </div>
          )}
          stickyHeader
          resizableColumns
          wrapLines={preferences.wrapLines}
          preferences={<Preferences preferences={preferences} setPreferences={setPreferences} />}
          onSelectionChange={async (event) => {
            if (FeatureFlagManager.getInstance().isFeatureFlagEnabled(alkimiaOptions, Feature.PERSIST_SELECTIONS)) {
              setIsProcessing(true);
              handleSelectionChanges(
                event.detail.selectedItems,
                selectedItems,
                tableItems,
                token,
                convertToSelectedResource,
                processResourceSelectionErrors
              )
                .then(updatedItems => setSelectedItems(updatedItems))
                .catch(error => {
                  console.error('Selection change failed:', error);
                  setSelectedItems(selectedItems);
                })
                .finally(() => setIsProcessing(false));
            } else {
              setSelectedItems(event.detail.selectedItems);
              saveSelectedItemsInSessionStorage && saveSelectedItemsInSessionStorage(targetAccountID!, regionID!, event.detail.selectedItems);
            }
          }
        }
        />
      </div>
    </>
  );
}

export const AlkimiaTableComponent = ResourceSelectionComponent;
