import axios from 'axios';
import { SelectedResource, ResourceSelectionPersistenceResult } from '@amzn/alkimia-model';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import { getLambdaApiUrl, getStandardHeader, processApiError } from '../../utils';
import { enableMockResponses, mockResourceSelectionPersistenceResult } from './mockResponse';

const mock = enableMockResponses;
const failPathMock = false;

export const deleteResourceSelections = async (
  token: CognitoToken,
  selectedResources: SelectedResource[]
): Promise<ResourceSelectionPersistenceResult> => {
  if (failPathMock) {
    throw new Error('Mock error: Failed to delete resource selections');
  }
  if (mock) return Promise.resolve(mockResourceSelectionPersistenceResult);

  return axios.post(`${getLambdaApiUrl()}/alkimia/deleteResourceSelections`,
    selectedResources,
    { headers: getStandardHeader(token) })
    .then((result) => result.data)
    .catch((error) => processApiError('deleteResourceSelections', error));
};
