import axios from 'axios';
import { SelectedResource } from '@amzn/alkimia-model';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import { getLambdaApiUrl, constructQueryString, getStandardHeader, processApiError } from '../../utils';
import { enableMockResponses, mockSelectedResources } from './mockResponse';

const mock = enableMockResponses;
const failPathMock = false;

export const getResourceSelections = async (
  token: CognitoToken,
  customerAccountId: string,
  customerAccountRegion: string,
): Promise<SelectedResource[]> => {
  if (failPathMock) {
    throw {
      response: {
        status: 500,
        data: { message: 'Internal Service Error' }
      }
    };
  }
  if (mock) return Promise.resolve(mockSelectedResources);

  return axios.get(`${getLambdaApiUrl()}/alkimia/getResourceSelections?${constructQueryString({ customerAccountId, customerAccountRegion })}`,
    { headers: getStandardHeader(token) })
    .then((result) => result.data)
    .catch((error) => processApiError('getResourceSelections', error));
};
