/* eslint-disable consistent-return */
import {
  DiscoverAwsResourceConfigurationOutput,
  ScanOperations,
  scanStatusWithDate,
} from '@amzn/alkimia-model';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import axios, { AxiosResponse } from 'axios';
import { constructQueryString, getLambdaApiUrl, getStandardHeader, processApiError } from '../../utils';
import { API_TIMEOUT_INTERVAL_IN_MS, SCANAWSACCOUNT_LAMBDA_TIMEOUT_IN_SECONDS, API_RETRY_INTERVAL_IN_MS } from '../../utils/Constants';

/**
 * Expected response from Proxy
 */
interface ProxyResults {
  accData: string;
  accScanStatus: string;
}

/**
 * Extending DiscoverAwsResourceConfigurationOutput as its not needed in the AlkimiaModel
 */
interface ParsedScanResponse extends DiscoverAwsResourceConfigurationOutput {
  proxyResponse?: AxiosResponse<ProxyResults>;
}

/**
 * Base scan which simply checks DDB for status of a scan
 * If 404 is caught, we will tell users there's nothing found
 * @returns current scanning status, if completed will return S3 scanned data, and response
 * so other scanOperation functions can leverage
 */
const invokeScanOperation = async (
  token: CognitoToken,
  customerAccountId: string,
  customerAccountRegion: string,
  scanOperation: ScanOperations,
): Promise<ParsedScanResponse> => {
  const params = {
    customerAccountId,
    customerAccountRegion,
    scanOperation,
    useNewDataModel: 'true',
  };

  try {
    const response = await axios.get<ProxyResults>(
      `${getLambdaApiUrl()}/datastore/scanAwsAccount?${constructQueryString(params)}`,
      {
        headers: getStandardHeader(token),
        timeout: API_TIMEOUT_INTERVAL_IN_MS,
      },
    );

    const { accData, accScanStatus } = response.data;
    return {
      messages: [],
      services: accData ? JSON.parse(accData) : [],
      mergedServices: [],
      accScanStatus: accScanStatus ? accScanStatus as scanStatusWithDate : undefined,
      proxyResponse: response,
    };
  } catch (error: any) {
    if (error.response) {
      const { status } = error.response;
      const { message, accScanStatus } = error.response.data || {};
      if (status === 403 || status === 404) {
        return { messages: [message], services: [], mergedServices: [], accScanStatus };
      }
    }
    return processApiError('getScan', error);
  }
};

/**
  * wrapper around invokeScanOperations with intent of getScan
  * @returns response from invokeScanOperation call
  */
export const getScan = async (
  token: CognitoToken,
  customerAccountId: string,
  customerAccountRegion: string,
): Promise<ParsedScanResponse> => invokeScanOperation(token, customerAccountId, customerAccountRegion, ScanOperations.getScan);

/**
  * wrapper around invokeScanOperations with intent of start
  * @returns response from invokeScanOperation call
  */
export const startScan = async (
  token: CognitoToken,
  customerAccountId: string,
  customerAccountRegion: string,
): Promise<ParsedScanResponse> => invokeScanOperation(token, customerAccountId, customerAccountRegion, ScanOperations.startScan);

/**
 * getScan with polling, this use case happens when
 * a pending scan is already happening
 * @returns data from S3 if found after polling and the status
 */
export const getScanWithPolling = async (
  token: CognitoToken,
  customerAccountId: string,
  customerAccountRegion: string,
): Promise<ParsedScanResponse> => {
  const startTime = Date.now();
  return new Promise((resolve) => {
    const intervalRetry = setInterval(async () => {
      try {
        const scanResponse = await getScan(token, customerAccountId, customerAccountRegion);
        const { proxyResponse: response } = scanResponse;

        // Respond with data from S3
        if (response!.status === 200) {
          clearInterval(intervalRetry);
          resolve({
            messages: [],
            services: scanResponse.services,
            mergedServices: [],
            accScanStatus: scanResponse.accScanStatus,
          });
        }

        if (Date.now() - startTime > SCANAWSACCOUNT_LAMBDA_TIMEOUT_IN_SECONDS * 1000) {
          clearInterval(intervalRetry);
          resolve({
            messages: [`Timed out after ${SCANAWSACCOUNT_LAMBDA_TIMEOUT_IN_SECONDS} seconds`],
            services: scanResponse.services ?? [],
            mergedServices: [],
            accScanStatus: scanResponse.accScanStatus,
          });
        }
      } catch (error: any) {
        if (error.response) {
          const { status } = error.response;
          const { message, accScanStatus } = error.response.data || {};

          if (status === 403 || status === 404) {
            clearInterval(intervalRetry);
            resolve({
              messages: [message],
              services: [],
              mergedServices: [],
              accScanStatus: accScanStatus ? JSON.parse(accScanStatus) as scanStatusWithDate : undefined,
            });
          }
        }
        clearInterval(intervalRetry);
        resolve(processApiError('getScanWithPolling', error));
      }
    }, API_RETRY_INTERVAL_IN_MS);
  });
};

/**
 * kicks off a full scan with polling. Leverages uses invokeScanOperation to startScan
 * @returns
 */
export const scanAwsAccount = async (
  token: CognitoToken,
  customerAccountId: string,
  customerAccountRegion: string,
): Promise<DiscoverAwsResourceConfigurationOutput> => {
  try {
    // Starts the scan
    const startResponse = await startScan(token, customerAccountId, customerAccountRegion);

    if (startResponse.proxyResponse!.status !== 202) {
      throw new Error(`Unexpected status code from proxy during polling ${startResponse.proxyResponse!.status}`);
    }

    // Poll for results
    return getScanWithPolling(token, customerAccountId, customerAccountRegion);
  } catch (error) {
    return processApiError('scanAwsAccount', error);
  }
};
